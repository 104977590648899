import axios from 'axios';

const state = {
  user: null,
}

const getters = {
  isAuthenticated: state => !! state.user,
  stateUser: state => state.user,
  accountUser: state => state.user.account_type,
}

const actions = {
  async logIn({dispatch}, user) {
    await axios.post('login', user)
    await dispatch('viewMe')
  },
  async viewMe({commit}) {
    let {data} = await axios.get('home')
    await commit('setUser', data)
  },
  async setData({commit}) {
    let {data} = await axios.get('home')
    await commit('setUser', data)
  },
  // eslint-disable-next-line no-empty-pattern
  async logOut({commit}) {
    let user = null
    commit('logout', user)
  },
}

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  logout(state, user){
    state.user = user;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}