/******************************************************************************
 *
 *                              BETAMATCH CONFIDENTIAL
 *                              _____________________
 *
 * [2022] BETAMATCH
 *  All Rights Reserved.
 *
 ******************************************************************************
 * This file, all
 * proprietary knowledge and algorithms it details are the sole property of
 * BETAMATCH unless otherwise specified. The software this file
 * belong with is the confidential and proprietary information of BETAMATCH.
 * ("Confidential Information"). You shall not disclose such
 * Confidential Information and shall use it only in accordance with the terms
 * of the license agreement you entered into with BETAMATCH.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from BETAMATCH.
 ******************************************************************************
 * @file    index.js
 * @author  Simeon Marlokov
 * @brief   Vue.js FE Router
******************************************************************************/

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  // Home
  {
    path: '/',
    name: '',
    component: () => import(/* webpackChunkName: "about" */ '../views/Landing.vue'),
    beforeEnter: (to, from, next) => {
      const { uri } = to.query;
      // let resolved = this.$router.resolve(uri)
      // console.log(resolved)
      // if(resolved.route.path != '404')
      if (uri != null && uri != '/') { 
        // console.log('false')
        next(false);
        router.push(uri);
      } else {
        // If URI is valid you get pushed to URI with next.
        // console.log('true')
        next();
      }
    },
    children: [
      {
        path: '/',
        name: 'Landing',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/external/Home.vue')
      },
      {
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "about" */ '../views/external/News.vue')
      },
      {
        path: '/services',
        name: 'Services',
        component: () => import(/* webpackChunkName: "about" */ '../views/external/Services.vue')
      },
      {
        path: '/disclamer',
        name: 'Disclamer',
        component: () => import(/* webpackChunkName: "about" */ '../views/external/Disclamer.vue')
      },
      {
        path: '/gdpr',
        name: 'GDPR',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/external/GDPR.vue')
      },
      // {
      //   path: '/terms-of-service',
      //   name: 'TermsOfService',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/external/ToS.vue')
      // },
      // {
      //   path: '/login/recovery',
      //   props: route => ({
      //     user_email: route.query.user_email,
      //     recover_password: route.query.recover_password, 
      //   }),
      //   name: 'LoginRecoverPassword',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/external/RecoverPassword.vue')
      // }
    ]
  },
  // Login
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  // Admin
  // {
  //   path: '/admin',
  //   name: '',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminHomeDashboard.vue'),
  //   meta: {requiresAdminAuth: true},
  //   children: [
  //     {
  //       path: '/',
  //       name: 'AdminHome',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/admin/pages/AdminHome.vue')
  //     },
  //     {
  //       path: '/admin/parcels',
  //       name: 'AdminParcels',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/admin/pages/AdminParcels.vue')
  //     },
  //     {
  //       path: '/admin/transaction-history',
  //       name: 'AdminTransactionHistory',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/admin/pages/AdminTransactionHistory.vue')
  //     },
  //     {
  //       path: '/admin/user-analytics',
  //       name: 'AdminUserAnalytics',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/admin/pages/AdminUserAnalytics.vue')
  //     },
  //     {
  //       path: '/admin/margin-control',
  //       name: 'AdminMarginControl',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/admin/pages/AdminMarginControl.vue')
  //     },
  //     {
  //       path: '/admin/coupon-codes',
  //       name: 'AdminCouponCodes',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/admin/pages/AdminCouponCodes.vue')
  //     },
  //     {
  //       path: '/admin/users',
  //       name: 'AdminUserControl',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/admin/pages/AdminUserControl.vue')
  //     },
  //   ]
  // },
  // Users - Buyers
  {
    path: '/home',
    name: '',
    component: () => import(/* webpackChunkName: "about" */ '../views/internal/Home.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '/',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/internal/UserProfile.vue')
      },
      {
        path: '/home/order',
        name: 'UserOrder',
        component: () => import(/* webpackChunkName: "about" */ '../views/internal/UserSendParcel2.vue')
      },
      {
        path: '/home/organization',
        name: 'UserOrganization',
        component: () => import(/* webpackChunkName: "about" */ '../views/internal/Organization.vue')
      },
      {
        path: '/home/history',
        name: 'UserOrderHistory',
        component: () => import(/* webpackChunkName: "about" */ '../views/internal/UserHistory.vue')
      },
      // {
      //   path: '/home/documentation',
      //   name: 'Documentation',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/internal/Documentation.vue'),
      //   children: [
      //     {
      //       path: '/setup',
      //       name: 'InitialStart',
      //       component: () => import(/* webpackChunkName: "about" */ '../views/internal/documentation/InitialSetup.vue'),
      //     },
      //   ],
      // },
      {
        path: '*', 
        name: 'Page404Internal',
        component: () => import('../views/internal/Page404.vue'),
      },
    ]
  },
  // Users - Sellers
  // {
  //   path: '/seller/home',
  //   name: '',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/pudo/PudoHome.vue'),
  //   meta: {requiresAuth: true},
  //   children: [
  //     {
  //       path: '/',
  //       name: 'PudoSendParcel',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/pudo/PudoSendParcel.vue')
  //     },
  //     {
  //       path: '/seller/address',
  //       name: 'PudoAddress',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/pudo/PudoAddress.vue')
  //     },
  //     {
  //       path: '/seller/parcel-history',
  //       name: 'PudoParcelHistory',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/pudo/PudoParcelHistory.vue')
  //     },
  //     {
  //       path: '/seller/profile',
  //       name: 'PudoOrganization',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/pudo/PudoOrganization.vue')
  //     },
  //     {
  //       path: '/pudo/documentation',
  //       name: 'PudoDocumentation',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/internal/Documentation.vue'),
  //       children: [
  //         {
  //           path: '/setup',
  //           name: 'PudoInitialStart',
  //           component: () => import(/* webpackChunkName: "about" */ '../views/internal/documentation/InitialSetup.vue'),
  //         },
  //       ],
  //     },
  //     {
  //       path: '*', 
  //       name: 'PudoPage404Internal',
  //       component: () => import('../views/internal/Page404.vue'),
  //     },
  //   ]
  // },
  // 404
  {
    path: '*', 
    name: 'Page404',
    component: () => import('../views/Page404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth', }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: 'smooth', }
    }
  }
})

// NEW
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (store.getters.isAdmin) {
      next('/admin/');
      return;
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    // next();
  }
   else {
    next();
  }
});

export default router
