import axios from 'axios';

import Vue from 'vue'
import VueMeta from 'vue-meta'


import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Google Analytics
// import VueAnalytics from 'vue-analytics'
// import Pixel from 'vue-analytics-facebook-pixel'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL

Vue.config.productionTip = false
Vue.use(VueMeta)
// Vue.use(Pixel)



// Vue.use(VueAnalytics, {
//   id: 'GTM-W4G2S32',
//   router
// });

// Vue.analytics.fbq.init('', {
//   em: 'user@mail.com'
// })

// NEW
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('logOut')
      return router.push('/')
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
