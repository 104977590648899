<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'App',
  metaInfo: {
      titleTemplate: '%s Betamatch.bg',
      htmlAttrs: {
        lang: 'bg'
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Вашият посредник за продажби, предлагане на услуги, сертифициране, поръчки за ремонт, строителство' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'услуги, покупка, посредник, сертифициране, строителство, ремонт, бета, бетаматч, beta, BETAMATCH' },
        { name: 'author', content: 'Бета Логистика ООД' },
      ]
    },

  data: () => ({
    //
    userIP: '',
  }),
  methods: {
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
        if (theme == "true") {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }
    }
    // this.getUserIP()
  },
};
</script>

