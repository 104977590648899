// import axios from 'axios';

  const state = {
    lang: { title: 'English', code: 'en' },
    locale: {
        en: {
          header_menu: {
            about_us: 'About us',
            services: 'Services',
            news: 'News',
            contact: 'Contact us',
            platform: 'Platform'
          },
          hero_section: {
            title: 'Your broker for sales, services, certification, maintenence and construction.',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            cta: 'Learn more',
          },
          about_section: {
            title: 'About us',
            body: 'Betamatch is a platform for commercial or dual purpose services and goods. The platform provides an intermediary service for the parties to the contract, providing:',
            listed_1: 'Opportunity for Sellers to reach a specialized market of Customers, while their interests and technology are protected. Their anonymity is guaranteed until an agreement is reached with the other party to the contract.',
            listed_2: 'Opportunity for Buyers to find services and solutions in multiple areas, guaranteeing correct execution of the order and undertaking the document processing process. The buyer provides their details and goes through an approval process to access the services offered.',
            conclusion: 'Betamatch facilitates the connection between both sides with the highest possible level of safety and confidentiality of information.',
          },
          services_section: {
            
          },
          contact_sections: {

          },
          footer_menu: {
            disclamer: 'Disclamer',
            cookies: 'Cookie Policy',
            tos: 'Terms of Service',
            gdrp: 'Privacy Policy',
          },
          cookies: {
            cookie_text: 'We use cookies on our website to provide you with the most relevant experience by remembering your preferences and repeat visits. By clicking "Accept" you consent to the use of ALL cookies.',
            cookie_btn: 'Accept',
          },
          login: {
            title: 'User Platform',
            email_label: 'Email',
            password_label: 'Password',
            login_btn: 'Login',
            back_btn: 'Back to home page',
          },
        },
        bg: {
            header_menu: {
              about_us: 'За нас',
              services: 'Услуги',
              news: 'Новини',
              contact: 'Контакт',
              platform: 'Платформа'
            },
            hero_section: {
              title: 'Вашият посредник за продажби, предлагане на услуги, сертифициране, поръчки за ремонт, строителство',
              body: 'Lorem ipsum dolor sit amet.',
              cta: 'Научи повече',
            },
            about_section: {
              title: 'За Нас',
              body: 'Бетаматч представлява платформа за услуги и стоки с търговско или двойно предназначение. Платформата предоставя посредническа услуга за страните по договора, осигуряваща:',
              listed_1: 'Възможност на Продавачите да достигнат специализиран пазар от Клиенти, като техните интереси и технология биват защитени. Анонимността им е гарантирана до сключването на споразумение с другата страна по договора.',
              listed_2: 'Възможност на Купувачите да намерят услуги и решения в множество сфери, като се гарантира коректно изпълнение на поръчката и се поема процесът по обработка на документите. Купувачът предоставя своите данни и преминава през процес на одобрение, за да получи достъп до предлаганите услуги.',
              conclusion: 'Бетаматч прави възможна връзката между двете страни с най-високо ниво на сигурност и конфиденциалност на информацията.',
            },
            services_section: {
            
            },
            contact_sections: {

            },
            footer_menu: {
                disclamer: 'Опровержение',
                cookies: 'Бисквитки',
                tos: 'Общи условия',
                gdrp: 'Политика за личните данни',
            },
            cookies: {
              cookie_text: 'Ние използваме бисквитки на нашия уебсайт, за да ви предоставим най-подходящото изживяване, като запомним вашите предпочитания и повтаряме посещенията. С натискането на „Приемам“ вие се съгласявате с използването на ВСИЧКИ бисквитки.',
              cookie_btn: 'Приемам',
            },
            login: {
              title: 'Потребителски панел',
              email_label: 'Ел. поща',
              password_label: 'Парола',
              login_btn: 'Влез',
              back_btn: 'Обратно към начална страница',
            },
        },
    }
  }
  
  const getters = {
    userLang: state => state.lang,
  }
  
  const actions = {
    async changeLanguage({commit}, lang) {
      commit('setUserLang', lang)
    },
  }
  
  const mutations = {
    setUserLang(state, lang) {
      state.lang = lang
    },
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }