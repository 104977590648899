import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#140E34',
            secondary: '#ffffff',
            accent: '#553BF8',
            third: colors.grey.lighten3,
            text: '#1F506F',
            background: '#ffffff',
            error: colors.red.accent4,
            warning: colors.amber.darken2,
            info: colors.grey.darken1,
            success: colors.green.darken1,
          },
          dark: {
            primary: '#ffffff',
            secondary: '#101010',
            accent: '#1F506F',
            text: '#ffffff',
            background: '#151515',
            error: colors.red.accent4,
            warning: colors.amber.darken4,
            info: colors.grey.darken3,
            success: colors.green.darken3,
          },
        },
        dark: false,
      },
});
// 101010 - 1F506F